import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useCatalogUrl } from '@bluheadless/ui-logic/src/hooks/useCatalogUrl'
import { useIsTouchDevice } from '@bluheadless/ui-logic/src/hooks/useIsTouchDevice'
import { bool, func, object, string } from 'prop-types'
import { useEffect, useRef } from 'react'
import { useHoverIntent } from 'react-use-hoverintent'
import RichContent from '../../particles/rich-content'
import { Link, LinkRoot } from './menu-first-level.styled'

const NavLink = ({
	url,
	eventType,
	navLinkHandler,
	itemId,
	title,
	hasChildren,
	type,
	entityId,
	identifier,
	...props
}) => {
	const clickedItem = useRef(false)
	const { menuItemClick } = useTrackingsActions()

	const [isHovering, ref] = useHoverIntent({
		timeout: 50,
		sensitivity: 7,
		interval: 100,
	})

	const productUrl = useCatalogUrl(url)

	const isTouchDevice = useIsTouchDevice()

	useEffect(() => {
		if (eventType === 'hover' && isHovering && !clickedItem.current) {
			navLinkHandler(itemId)
		}
	}, [isHovering, navLinkHandler, eventType, itemId])

	useEffect(() => {
		if (!isHovering) {
			clickedItem.current = false
		}
	}, [isHovering])

	return (
		<LinkRoot ref={ref}>
			<Link
				underline="none"
				href={eventType == 'click' && hasChildren ? null : type === 'product' ? productUrl : url ? url : null}
				onClick={(e) => {
					if (eventType === 'click' || isTouchDevice) {
						navLinkHandler(itemId)
					} else {
						clickedItem.current = true
						menuItemClick({ event: e, entityType: type, entityId, entityName: identifier })
					}
				}}
				{...props}
			>
				<RichContent content={title} />
			</Link>
		</LinkRoot>
	)
}

NavLink.defaultProps = {}

NavLink.propTypes = {
	eventsHandler: func,
	hasChildren: bool,
	iconDropdownProps: object,
	navLinkHandler: func,
	target: string,
	title: string,
	type: string,
	url: string,
}

export default NavLink
